export class HeaderState {
  isHambugerMenu = false;
  account = new AccountState();
}

export class AccountState {
  avatar = "";
  fullName = "";
  email = "";
  id = "";
  role = 0;
  planType = 1;
  primaryPaymentMethodInfo = '';
}
