export default {
  member: "メンバー一覧",
  invited: "招待中",
  buttonAdd: "メンバーを招待する",
  searchPlaceholder: "メンバーを検索する",
  userInfo: "メンバー氏名",
  permission: "アカウントの種類",

  optionDetails: "プロフィールを見る",
  optionTriCircle: "2要素認証をリセット",
  optionBlock: "メンバーのブロック",
  optionUnblock: "メンバーのブロックを解除",
  optionDelete: "メンバーを削除",

  optionReinvite: "招待メールを再送する",
  optionCancelInvite: "招待を取り消す",

  changeButton: "変更する",
  changeOwnerTitle: "オーナー変更",
  changeOwnerMessage:
    "自分の所有権をメンバーに譲渡することができます。所有権を譲渡した後は、正会員となります。その際、一部の権限が失われます。(Example text)",

  reAuthTitle: "2要素認証をリセット",
  reAuthMessage:
    "このメンバーの2要素認証の設定をリセットしますか？<br/>次回ログインした際に2要素認証の再設定が要求されます。",

  blockTitle: "メンバーのブロック",
  unblockTitle: "メンバーのブロックを解除",
  unblockMessage:
    "<span class='accent-text'>{name}（{email}）</span>ブロックを解除しますか？",
  blockMessage:
    "<span class='accent-text'>{name}（{email}）</span>をブロックしますか？<br/>ブロックするとこのメンバーはログインできなくなります。",
  blockButton: "ブロック",
  unblockButton: "ブロックを解除する",

  deleteTitle: "メンバーを削除",
  deleteMessage:
    "<span class='accent-text'>{name}（{email}）</span>を削除してもよろしいですか。<br/>メンバーは完全にアカウントにアクセスできなくなります。",
  deleteButton: "削除する",

  inviteResendTitle: "招待メールを再送する",
  inviteResendMessage:
    "<span class='accent-text'>{name}（{email}）</span>に招待メールを<br/>再送してもよろしいですか？",
  resendButton: "送信する",

  inviteCancelTitle: "招待の取り消し",
  inviteCancelMessage:
    "<span class='accent-text'>{name}（{email}）</span>への招待を取り消してもよろしいですか？",
  cancelButton: "取り消す",

  popupConfirmText: "リセットする",

  addPopupTitle: "メンバーの招待",
  addPopupTitle2: "メンバーを招待する",
  addPopupTitle3: "メンバーを招待する",
  addPopupMessage:
    "招待するメンバーの権限を選んでください。<br/>選択した権限は後から変更することも可能です。",

  addPopupOption1: "オーナー",
  addPopupOptionMessage1:
    "本サービス上の利用者コンテンツを使用でき、<br/>全ての権限を保有する利用者を意味します。",
  addPopupOption2: "マネージャー",
  addPopupOptionMessage2:
    "本サービス上の利用者コンテンツを使用でき、<br/>メンバーを追加・削除できる利用者を意味します。",
  addPopupOption3: "メンバー",
  addPopupOptionMessage3:
    "本サービス上の利用者コンテンツを使用できる利用者を意味します。",
  addPopupSubtitle: "許可の説明はこちら",
  emptyGroup: "グループはありません",

  secondSectionTopTitle: "メンバーリスト",
  secondAddButton: "メンバーを招待する",
  secondPopupMailHint: "【必須】メールアドレス",
  secondPopupNameHint: "【必須】氏名",
  secondBackButton: "本会員権限選択へ戻る",
  secondNextButton: "次へ",

  thirdPopupSearchHint: "(任意) グループ名の選択",
  thirdPopupMessage:
    "メンバーは、1つ以上のグループに所属することが可能です。<br/>メンバーをグループに追加することで、<br/>契約書を効率的に管理することができます。",
  sendInvitation: "招待する",
  backInvitation: "メンバー招待へ戻る",

  role1: "オーナー",
  role2: "マネージャー",
  role3: "メンバー",

  changeRolePopupTitle: "アカウントの種類を変更する",

  emptyList:
    "現在、このフォルダにはメンバーがいません。</br> ボタン「メンバーを招待する」を選択して、新しいメンバーを追加してください。",
};
