export default {
  copyright: "Digital Sign ©2022",
  notation: "特定商取引法に基づく表記",
  operatingCompany: "運営会社",
  media: "メディア",
  privacy: "プライバシーポリシー",
  termsOfService: "利用規約",
  faq: "よくある質問",
  contact: "お問い合わせ",
};
