import moment from "moment";

export class NotificationInformation {
  subject = "";
  body = "";
  emails: Array<string> = [];
  notificationDate = Date.now();

  constructor(
    subject = "",
    body = "",
    emails: Array<string> = [],
    notificationDate = Date.now()
  ) {
    this.subject = subject;
    this.body = body;
    this.emails = emails;
    this.notificationDate = notificationDate;
  }

  static fromJson(notification: any) {
    const { subject, body, emails, notificationDate } = notification;
    return new NotificationInformation(subject, body, emails, notificationDate);
  }

  toJson() {
    return {
      subject: this.subject,
      body: this.body,
      emails: this.emails,
      notificationDate: moment(this.notificationDate).format(),
    };
  }

  isEmpty() {
    return !(
      this.subject.trim() != "" &&
      this.body.trim() != "" &&
      this.emails.length > 0 &&
      this.notificationDate
    );
  }
}
