export default {
  custom: "オリジナル雛形",
  buildin: "公式雛形",

  searchHint: "雛形を検索する",
  tableTitle: "雛形",

  makeContract: "契約書を作成する",

  addButton: "雛形を作成する",
  addFolder: "フォルダを作成する",

  editOption: "編集",
  editTemplate: "雛形を変更する",
  renameFolder: "フォルダ名を変更",
  editPermission: "共有の設定を見る",
  moveOption: "フォルダを移動する",
  downloadOption: "ダウンロード",
  deleteOption: "削除",
  nextButton: "次へ",
  cancelEdit: "編集をキャンセル",
  templateTitle: "雛形の契約書名",
  contractA: "CONTRACT A",
  fileAdd: "ファイルの追加",
  docxConfirm: "確認",
  docxMessage: "この雛形で契約を作成する",
  docxReturn: "フォルダ{folder}に戻る",
  updateMessage: "テンプレートを更新する",
  addDocument: "書類の追加",
  select: "選択",
  updateOption1: "有効",
  updateOption2: "無効",

  progressAddFile: "書類の追加",
  progressSigner: "署名者の設定",
  progressVars: "入力項目の設定",
  progressConfirm: "雛形の確認",
  progressDone: "完了",
  noItem:
    "現在、このフォルダにはファイルがありません。<br/>ボタン 「雛形を作成する」を選択して、新しい雛形を追加してください。",
  noBuiltinItem:
    "現在、公式雛形がありません。<br/>追加されるまでお待ちください。",
  lastUpdate: "最終更新日",
  selectAction: "選択アクション",

  deleteTemplatePopupTitle: "雛形を削除",
  allFolder: "雛形の管理",

  deleteTemplateMessage1: "を削除してもよろしいですか？",
  deleteTemplateMessage2: "雛形は完全に削除されます。",
};
