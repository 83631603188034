import { ActionTree } from "vuex";
import { RootState } from "../rootState";
import { ContractSigner } from "@/models/contracts/ContractSigner";
import { ContractViewer } from "@/models/contracts/ContractViewer";
import { CustomField } from "@/models/contracts/CustomField";
import { FileInfo } from "@/models/contracts/FileInfo";
import { NotificationInformation } from "@/models/contracts/NotificationInformation";

import { TemplateState } from "./state";

const actions: ActionTree<TemplateState, RootState> = {
  resetState({ commit }) {
    commit("resetState");
  },
  setTemplateBasicInfo({ commit }, templateInfo: any) {
    commit("setTemplateBasicInfo", templateInfo);
  },

  setTemplateDetailInfo({ commit }, templateInfo: any) {
    commit("setTemplateDetailInfo", templateInfo);
  },

  setFiles({ commit }, files: Array<FileInfo>) {
    commit("setFiles", files);
  },

  setSigners({ commit }, signer: Array<ContractSigner>) {
    commit("setSigners", signer);
  },

  setSecurity({ commit }, securityInfo: any) {
    commit("setSecurity", securityInfo);
  },

  setCustomFields({ commit }, customFields: Array<CustomField>) {
    commit("setCustomFields", customFields);
  },

  setViewers({ commit }, viewers: Array<ContractViewer>) {
    commit("setViewers", viewers);
  },

  setIsTemplate({ commit }, isTemplate: boolean) {
    commit("setIsTemplate", isTemplate);
  },

  setIsEditing({ commit }, isEditing: boolean) {
    commit("setIsEditing", isEditing);
  },

  setAllowTransfer({ commit }, isAllow: boolean) {
    commit("setAllowTransfer", isAllow);
  },

  setTimeStamp({ commit }, needTimeStamp: boolean) {
    commit("setTimeStamp", needTimeStamp);
  },

  setStatus({ commit }, status: number) {
    commit("setStatus", status);
  },

  setMessage({ commit }, message: string) {
    commit("setMessage", message);
  },

  setNotification(
    { commit },
    notificationInformation: NotificationInformation
  ) {
    commit("setNotification", notificationInformation);
  },

  setId({ commit }, id: string) {
    commit("setId", id);
  },

  setFolder({ commit }, folder: any) {
    commit("setFolder", folder);
  },

  setCompany({ commit }, company: any) {
    commit("setCompany", company);
  },
  setError({ commit }, stepError: number) {
    commit("setError", stepError);
  },
  setCountSigned({ commit }, countSigned: number) {
    commit("setCountSigned", countSigned);
  },

  setCreatedFromTemplate({commit}, createdFromTemplate: string) {
    commit("setCreatedFromTemplate", createdFromTemplate);
  }
};

export default actions;
