import { ActionTree } from "vuex";
import { RootState } from "../rootState";
import { ErrorPageState } from "./state";

const actions: ActionTree<ErrorPageState, RootState> = {
  setErrorPage({ commit }, errors: any) {
    commit("setErrorPage", errors);
  },
};

export default actions;
