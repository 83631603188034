export default {
  titleSection1: "企業情報の設定",
  titleSection2: "セキュリティ設定",
  titleSection3: "メール通知設定",
  titleSection4: "アラート通知先の設定",

  bannerText: "企業情報",

  buttonText: "編集",

  titleSymbol: "企業ロゴ",
  titleCompanyName: "企業名",
  titlePosition: "企業住所",
  titlePhone: "電話番号",
  titlePresent: "代表者氏名",
  titlePartnerName: "パートナー企業",
  titleSellerName: "紹介営業担当者",
  titleClientPhone: "管理番号",
  titleStampImage: "オリジナル印影",
  titleEmail: "メールアドレス",
  titlePassword: "パスワード",
  retypePassword: "パスワードの再入力",

  titleRequestTwoFactor: "すべてのメンバーに<br />2要素認証を要求する",
  titleRequestTwoFactorMob: "すべてのメンバーに2要素認証を要求する",
  on: "オン",
  off: "オフ",

  titleNotificationTooltip:
    "アラート通知先のメールアドレスをデフォルト設定できます。<br/>また、他のメニューから契約書ごとに変更することもできます。",
  titleNotificationTooltipMob:
    "アラート通知先のメールアドレスをデフォルト設定できます。また、他のメニューから契約書ごとに変更することもできます。",

  buttonAddUser: "アラート通知先の設定をする",
  buttonRemoveUser: "削除",

  editProfile: "プロフィール画像の変更",
  editSymbol: "企業ロゴの変更",
  editStamp: "オリジナル印影の変更",

  editCompanyName: "企業名の変更",
  companyNameLabel: "【必須】企業名",

  editPhoneNumber: "電話番号の変更",
  phoneNumberLabel: "【必須】電話番号",

  editRepresentativeName: "代表者氏名の変更",
  representativeLastNameLabel: "【必須】代表者氏名(姓)",
  representativeFirstNameLabel: "【必須】代表者氏名(名)",

  editAddress: "企業住所の変更",
  editPostalCodeLabel: "【必須】郵便番号",
  editPrefectureLabel: "【必須】都道府県",
  editCityLabel: "【必須】市区町村",
  editAddressLabel: "【必須】丁目、番地",
  editBuildingRoomLabel: "(任意)建物名、部屋番号",

  editConfirmButton: "変更する",

  editReferralCompany: "パートナー企業の変更",
  editReferralCompanyLabel: "【必須】パートナー企業",

  editReferralPersonName: "紹介営業担当者の変更",

  editControlNumber: "管理番号の変更",
  controlNumberLabel: "【必須】管理番号",

  companyNameHint: "〇〇株式会社",

  addAuthTitle: "アラート通知先の設定",
  addAuthLabel: "アラート通知先",
  addAuthMessage:
    "アラート通知先のメールアドレスをデフォルト設定できます。<br />また、他のメニューから契約書ごとに変更することもできます。",
  addAuthSearchHint: "メールアドレス、または既存のメンバーの名前を入力する",
  addAuthButton: "設定する",

  twoFAPopupTitle: "すべてのメンバーに2要素認証を要求する",
  twoFAPopupMessageOn:
    "すべてのメンバーに2要素認証設定を要求しますか？<br/>次回メンバーがログインした際に<br/>2要素認証を設定する必要があります。",
  twoFAPopupMessageOff:
    "すべてのメンバーに2要素認証設定を要求しないように変更しますか？<br/>(既に設定されている2要素認証は解除されません)",
};
