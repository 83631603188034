import { MutationTree } from "vuex";
import { ContractSigner } from "@/models/contracts/ContractSigner";
import { ContractViewer } from "@/models/contracts/ContractViewer";
import { CustomField } from "@/models/contracts/CustomField";
import { FileInfo } from "@/models/contracts/FileInfo";
import { TemplateInformation } from "@/models/contracts/Information";
import { NotificationInformation } from "@/models/contracts/NotificationInformation";

import { TemplateState } from "./state";

const mutations: MutationTree<TemplateState> = {
  resetState(state) {
    state.id = null;
    state.folderId = "";
    state.folderName = "";
    state.title = "";
    state.contractPartnerName = "";
    state.contractInformation = new TemplateInformation();
    state.files = [];
    state.contractSigners = [];
    state.contractViewers = [];
    state.contractCustomFields = [];
    state.isTemplate = true;
    state.isXIDRequired = false;
    state.isOnlyPartnerXIDRequired = false;
    state.is2FARequired = true;
    state.isSignerTransferAllowed = true;
    state.needTimeStamp = true;
    state.createdFromTemplate = "";
    state.status = 0;
    state.stepError = -1;
    state.message = "";
    state.notification = new NotificationInformation();
  },
  setTemplateBasicInfo(state, info: any) {
    state.id = info.id ?? null;
    state.title = info.title;
    state.contractPartnerName = info.contractPartnerName;
  },

  setCreatedFromTemplate(state, createdFromTemplate: string) {
    state.createdFromTemplate = createdFromTemplate;
  },

  setTemplateDetailInfo(state, info: any) {
    state.contractInformation = info;
  },

  setFiles(state, files: Array<FileInfo>) {
    state.files = files;
  },

  setSigners(state, signers: Array<ContractSigner>) {
    state.contractSigners = signers;
  },

  setSecurity(state, securityInfo: any) {
    state.isXIDRequired = securityInfo.isXIDRequired;
    state.isOnlyPartnerXIDRequired = securityInfo.isOnlyPartnerXIDRequired;
    state.is2FARequired = securityInfo.is2FARequired;
    state.isSignerTransferAllowed = securityInfo.isSignerTransferAllowed;
    state.needTimeStamp = securityInfo.needTimeStamp;
  },

  setCustomFields(state, customFields: Array<CustomField>) {
    state.contractCustomFields = customFields;
  },

  setViewers(state, viewers: Array<ContractViewer>) {
    state.contractViewers = viewers;
  },

  setIsTemplate(state, isTemplate: boolean) {
    state.isTemplate = isTemplate;
  },

  setIsEditing(state, isEditing: boolean) {
    state.isEditing = isEditing;
  },

  setAllowTransfer(state, isAllow: boolean) {
    state.isSignerTransferAllowed = isAllow;
  },

  setTimeStamp(state, needTimeStamp: boolean) {
    state.needTimeStamp = needTimeStamp;
  },

  setStatus(state, status: number) {
    state.status = status;
  },

  setMessage(state, message: string) {
    state.message = message;
  },

  setNotification(state, info: NotificationInformation) {
    state.notification = info;
  },

  setId(state, id: string) {
    state.id = id;
  },

  setFolder(state, folder: any) {
    state.folderId = folder.folderId;
    state.folderName = folder.folderName;
  },

  setCompany(state, company: any) {
    if (company.name) {
      state.company.name = company.name;
    }
    if (company.logo) {
      state.company.logo = company.logo;
    }
  },
  setError(state, stepError: number) {
    state.stepError = stepError;
  },
  setCountSigned(state, countSigned: number) {
    state.countSigned = countSigned;
  },
};

export default mutations;
