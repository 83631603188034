export default {
  loginTagline: "現場の声から生まれた電子契約サービス",
  loginTitle: "Digital Signにログイン",
  loginEmail: "メールアドレス",
  loginPassword: "パスワード",
  loginBtn: "ログイン",
  showPassword:"パスワードを表示する",
  loginForgotPassword: "パスワードを忘れた場合は",
  loginOr: "または",
  loginXidBtn: "xIDでログイン",
  loginRegisText: "初めてDigital Signをご利用の方は",
  loginCreateAccount: "アカウントを作成する。",
  thisText: "こちら",

  forgotPassTagline: "FORGET PASSWORD",
  forgotPassTitle: "パスワードのリセット",
  forgotPassText: "登録したメールアドレスを入力してください。",
  forgotPassEmail: "メールアドレス",
  forgotPassCancelBtn: "キャンセル",
  forgotPassSendBtn: "リセットする",

  forgotPassConfirmText:
    "にメールを送信しました。<br/>メールに記載の手順に従って再設定を行ってください。",
  forgotPassConfirmBtn: "ログインに戻る",

  signupTagline: "CREATE ACCOUNT",
  signupTitle: "アカウントを作成する",
  signupEmail: "メールアドレス",
  signupPassword: "パスワード",
  signupPasswordConfirm: "パスワードを認証する",
  signupCreateBtn: "作成する",
  signupOr: "または",
  signupXidBtn: "xIDでアカウントを作成する",
  signupXidHint1:
    "xIDでログインに問題がありますか？<a class='link' href='https://help.xid.inc/xid' target='_blank'>よくある質問</a>をご覧ください。",
  signupXidHint2:
    "App StoreまたはGoogle Playを使用してxIDをインストールできます。",
  signupLoginText: "既にアカウントをお持ちの方は",
  signupEmailConfirmText:
    "確認メールが<span>{email}</span>に送信されました。受信トレイを確認し、基本登録を完了することを確認してください。",

  twoFactorTagline: "認証",
  twoFactorTitle: "2要素認証",

  twoFactorMessage:
    "認証システムアプリを起動し、6桁の認証コードをご確認の上、画面に表示されている入力項目にご入力ください。",

  twoFactorHint: "認証コード",
  twoFactorCancel: "キャンセル",
  twoFactorConfirm: "認証する",

  emailConfirmTagline: "EMAIL CONFIRMED",
  emailConfirmTitle: "メールアドレスが正常に確認されました",
  emailConfirmText:
    "メールアドレスが正常に確認されました。<br/>以下のボタンよりパスワードの再設定に進んでください。",
  emailConfirmButton: "パスワードを再設定する",

  passwordNewTagline: "NEW PASSWORD",
  passwordNewTitle: "パスワードの再設定",
  passwordNewHint: "新しいパスワード",
  passwordNewRetypeHint: "新しいパスワードの再入力",
  passwordNewButton: "再設定",

  passwordNewSuccessMessage: "新しいパスワードを使用してログインしてください。",
  passwordNewSuccessButton: "ログインに戻る",
  returnLogin: "ログインに戻る",

  signupPasswordConfirmMail:
    "確認メールが<span class='mail-address'>coco_mizuhara@gmail.com</span>に送信されました。<br/>受信トレイを確認し、基本登録を完了することを確認してください。",
};
