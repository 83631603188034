export class PopupState {
  // Folder
  FolderAdd = false;
  FolderRename = false;
  FolderEditAccess = false;
  FolderContractMove = false;
  // Contract
  ContractTransfer = false;
  ContractInformation = false;
  ContractTransferConfirm = false;
  ContractEditAccess = false;
  ContractEditCancel = false;
  ConfirmDelete = false;
  BrowseFolder = false;
  // Template
  TemplateMove = false;
  SelectTemplate = false;
  TemplateConfirmDelete = false;
  // Folder Notification
  EditFolderNotification = false;

  ContractHistory = false;
  ContractStamp = false;
  //Sign
  RequestCode = false;
  SignCode = false;

  RejectSign = false;
  //SignXID
  RequestLoginXID = false;
  SelectFileXID = false;
  SignPinXID = false;
}
