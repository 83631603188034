import moment from "moment";

export class TemplateInformation {
  contractId = "";
  companyId = "";
  contractOverview = "";
  contractConclusionDate: Date | undefined;
  contractStartDate: Date | undefined;
  contractEndDate: Date | undefined;
  terminationNoticePeriod = "";
  isAutomaticallyUpdated = "null";
  controlNumber = "";
  transactionAmount = 0;
  freeText = "";

  constructor(
    contractId = "",
    contractOverview = "",
    contractConclusionDate = undefined,
    contractStartDate = undefined,
    contractEndDate = undefined,
    terminationNoticePeriod = "",
    isAutomaticallyUpdated = "null",
    controlNumber = "",
    transactionAmount = 0,
    freeText = ""
  ) {
    this.contractId = contractId;
    this.contractOverview = contractOverview;
    this.contractConclusionDate = contractConclusionDate;
    this.contractStartDate = contractStartDate;
    this.contractEndDate = contractEndDate;
    this.terminationNoticePeriod = terminationNoticePeriod;
    this.isAutomaticallyUpdated = isAutomaticallyUpdated;
    this.controlNumber = controlNumber;
    this.transactionAmount = transactionAmount;
    this.freeText = freeText;
  }

  static fromJson({
    contractId = "",
    contractOverview = "",
    contractConclusionDate = undefined,
    contractStartDate = undefined,
    contractEndDate = undefined,
    isAutomaticallyUpdated = null,
    controlNumber = "",
    transactionAmount = 0,
    freeText = "",
    terminationNoticePeriod = "",
  }) {
    const isAutomaticallyUpdatedText =
      isAutomaticallyUpdated == true
        ? "true"
        : isAutomaticallyUpdated == false
        ? "false"
        : "null";

    return new TemplateInformation(
      contractId,
      contractOverview,
      contractConclusionDate,
      contractStartDate,
      contractEndDate,
      terminationNoticePeriod,
      isAutomaticallyUpdatedText,
      controlNumber,
      transactionAmount,
      freeText
    );
  }

  toJson() {
    return {
      contractId: this.contractId,
      contractOverview: this.contractOverview,
      contractConclusionDate: this.contractConclusionDate
        ? moment(this.contractConclusionDate).format()
        : "",
      contractStartDate: this.contractStartDate
        ? moment(this.contractStartDate).format()
        : "",
      contractEndDate: this.contractEndDate
        ? moment(this.contractEndDate).format()
        : "",
      terminationNoticePeriod: this.terminationNoticePeriod,
      isAutomaticallyUpdated:
        this.isAutomaticallyUpdated == "true"
          ? true
          : this.isAutomaticallyUpdated == "false"
          ? false
          : null,
      controlNumber: this.controlNumber ? this.controlNumber.toString() : "",
      transactionAmount: this.transactionAmount,
      freeText: this.freeText,
    };
  }
}
