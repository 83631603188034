export default {
  firstNameHint: "名",
  lastNameHint: "姓",
  phoneHint: "09012345678",
  logoText: "画像のアップロード",
  logoDeleteText: "画像削除",
  submitText: "次へ",
  backText: "前に戻る",

  welcomeButtonText: "アカウント情報を入力する",
  welcomeTaglineText: "WELCOME",
  welcomeTitleText: "Digital Signへようこそ",
  welcomeInfoLine:
    "メールアドレスが正しく確認されました。<br/>続いてアカウント情報を入力してください。",

  termTaglineText: "TERMS AND PRIVACY POLICY",
  termTitleText: "利用規約とプライバシーポリシー",
  termFirstCheckbox: "利用規約とプライバシーポリシーに同意します。",
  termSecondCheckbox: "Digital Signからのキャンペーン通知を受け取ります。",
  termButtonText: "Digital Signをはじめる",
  termTitleTag: "利用規約",
  termPrivacy: "プライバシーポリシー",

  organizationTaglineText: "COMPANY",
  organizationTitleText: "企業情報",
  organizationLogo: "（任意）企業のロゴ",
  organizationName: "企業名",
  organizationNameHint: "〇〇株式会社",
  organizationAddress: "企業住所",
  organizationAddressHint: "郵便番号 1234567",
  organizationAutofillAddress: "自動入力",
  organizationProvinceDropdown: "都道府県を選択してください",
  organizationCityHint: "市区町村",
  organizationStreetHint: "丁目、番地",
  organizationDepartmentHint: "（任意）建物名、部屋番号",
  organizationPhone: "電話番号",
  organizationProxyName: "代表者氏名",
  organizationOtherName: "ご利用のきっかけ",
  organizationOtherNameHint: "株式会社Wiz",
  organizationSellerName: "（任意）紹介してくれた営業担当者",
  organizationPhoneOther: "（任意）管理番号",

  profileTaglineText: "MY PAGE",
  profileTitleText: "マイページ",
  profileLogo: "（任意）プロフィール画像",
  profileRole: "（任意）役職 ",
  profileRoleHint: "役職",
  profilePhone: "（任意）電話番号 ",
  profileName: "姓／名",
  profileEmail: "メールアドレス",
  profileEmailHint: "coco_mizuhara@gmail.com",

  confirmTaglineText: "認証",
  confirmTitleText: "2要素認証",
  confirmSubtitle:
    "2要素認証を有効化することで、よりセキュリティを強化することができます。設定を行うことにより2要素認証が使用可能になります",
  confirmToggleText: "2要素認証を有効化する",
  confirmLabelCode: "シークレットコード",
  confirmDetail1:
    "1. 認証システムアプリを起動して表示されているQRコードを読み取るか、シークレットコードを入力してください。また、シークレットコードは安全に保管してください。",
  confirmDetail2:
    "2. 認証システムアプリに表示された認証コード（６桁の数値）を入力してください。",
  confirmCodeHint: "認証コード",
  confirmValidateButtonText: "認証する",
  confirmSuccess: "認証コードが正常に確認されました",

  completeTaglineText: "COMPLETED",
  completeTitleText: "アカウント情報の入力が完了しました。",
  completeInfoLine:
    "Digital Signへのご登録ありがとうございます。<br/>チームメンバーを追加してあなたのビジネスを加速させましょう。",
  completeCreditCard: "スタンダードプランに申し込み",
  completeAddButton: "メンバーを招待する",
  completeLater: "あとで",

  helpLinkOrganizationFirst: "登録方法は",
  helpLinkPersonalFirst: "個人の方は",
  helpLinkHere: "こちら",
  helpLinkLast: "を参照下さい。"
};
