export default {
  paymentUpdate: "決済情報を更新する",
  invoice: "請求書",
  invoiceName: "請求書名",
  fee: "料金",
  status: "状態",
  download: "ダウンロード",
  paid: "支払い済み",
  unpaid: "未払い",
  invoiceDate: "支払日",
  yearInvoice: "の請求書",
  popupDowngradeTitle: "スタンダードプランの解約",
  popupConfirmDowngradeButton: "解約手続きに進む",
  downgradeDescription:
    "スタンダードプランを解約してもよろしいですか？</br>解約後は自動的にフリープランに移行します。</br>※フリープランでは契約書の作成・送信は行えません。",
  // invoice payment updated
  paymentTitle: "PAYMENT METHOD",
  invoicePaymentUpdated: "決済情報が更新されました",
  paymentStop: "スタンダードプランをやめる",
  paymentMessage:
    "決済情報が正常に更新されました。<br/>新しいお支払い方法は次回のお支払いから適用されます。",
  invoiceList: "請求書一覧に戻る",
  creditCardNumbeInUse: "利用中クレジットカード番号",
  year: "年",
  all: "全て",
};
