import { defineRule } from "vee-validate";
import { isEmail, hasNumber, hasAlphabet } from "@/lib/utility/stringUtil";

// Install rules

defineRule("required", (value: string | any[]) => {
  if (!value || !value.length) {
    return "error_required";
  }
  return true;
});

defineRule("dateRequired", (value: Date | any[]) => {
  if (!value) {
    return "error_required";
  }
  return true;
});

defineRule("name", (value: string) => {
  if (!/^([^0-9]*)?$/i.test(value)) {
    return "error_format_name";
  }
  return true;
});

defineRule("email", (value: string) => {
  if (!isEmail(value)) {
    return "error_format_email";
  }

  return true;
});

defineRule("number", (value: string) => {
  if (!/^(0|[0-9]\d*)?$/i.test(value)) {
    return "error_format_number";
  }
  return true;
});

defineRule("creditCard", (value: string) => {
  // Visa, MasterCard, American Express
  if (
    !/^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13})$/i.test(
      value
    )
  ) {
    return "error_format_credit_card";
  }
  return true;
});

defineRule("cvv", (value: string) => {
  if (!/^.{3,4}$/i.test(value)) {
    return "error_format_cvv";
  }
  return true;
});

defineRule("holdername", (value: string) => {
  if (!/^[a-zA-Z ]+$/i.test(value)) {
    return "error_format_holdername";
  }
  return true;
});

defineRule("postalCode", (value: string) => {
  if (!/^[\d./-]*$/i.test(value)) {
    return "error_format_postal_code";
  }
  return true;
});

defineRule("password", (value: string) => {
  if (!hasAlphabet(value) || !hasNumber(value) || value.length < 8) {
    return "error_format_password";
  }

  return true;
});

defineRule("controlNumber", (value: string) => {
  if (!/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/i.test(value)) {
    return "error_format_control_number";
  }
  return true;
});
