export default {
  bannerText: "新しい雛形",

  labelTitle: "雛形を作成する",
  labelNameContract: "テンプレート:",

  signerListTile: "署名者一覧",
  viewerList: "閲覧者一覧",
  viewerAdd: "閲覧者の追加",
  emptyViewerMessage: "「閲覧者の追加」を選択して、新しい閲覧者を追加します。",
  emptySignerMessage: "追加された署名者はありませんでした。",
  paymentCompleted: "COMPLETED",
  paymentUpdated: "雛形を登録しました",
  paymentMessage1: "雛形が正しく登録されました。",
  paymentMessage2: "この雛形はいつでも編集することができます。",
};
