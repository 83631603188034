import { MutationTree } from "vuex";
import { PopupState } from "./state";

const mutations: MutationTree<PopupState> = {
  setPopup(state: PopupState, key: keyof PopupState) {
    state[key] = true;
  },

  unsetPopup(state: PopupState, key: keyof PopupState) {
    state[key] = false;
  },
};

export default mutations;
