export default {
  title: "株式会社C-mind",
  addContract: "契約書を作成する",
  pricing: "プランを更新",
  home: "ホーム",
  contractManagement: "契約書の管理",
  folderManagement: "契約書の管理",
  templateManagement: "雛形の管理",
  accountManagement: "アカウントの管理",
  contactInfo: "顧客一覧",
  notation: "特定商取引法に基づく表記",
  operatingCompany: "運営会社",
  media: "メディア",
  organizationDetails: "企業の詳細",
  teamMembers: "メンバー一覧",
  group: "グループ",
  invoice: "請求書",
  contactInformation: "連絡先",
  privacy: "プライバシーポリシー",
  termsOfUse: "利用規約",
  faq: "よくあるご質問",
  contactUs: "お問い合わせ",
  copyright: "Digital Sign ©2022",
};
