export default {
  addFolder: "グループ情報",
  searchFolderHint: "共有設定したいフォルダ・書類を選択してください",

  checkFolderButton: "このフォルダへ移動",
  selectFolderButton: "選択する",

  optionFilter: "閲覧可能",

  addFolderButton: "設定する",
  currentFolder: "共有している書類一覧",
  alertNotificaionMessage: "カスタマイズ可能なアラート通知を追加します。",
  addSubject: "件名を追加",
  inputMessage: "メッセージを入力します",
  inputEmailMessage: "メールアドレスを入力してください",
  dateSelect: "日付を選択してください",
  alertNotification: "アラート通知受信者",
  sendingDateSelect: "アラート通知送信日を選択",
  subject: "件名",
  notificationMessage: "通知メッセージ",
  transmissionTime: "アラート通知送信時刻を選択",
  receiver: "受信者",
  notificationDateTime: "通知日時",
  notificationDateTimeplaceholder:"時刻を選択してください",
  alertNotificationTitle: "アラート通知のタイトル",
  searchResult: "の{total}件の検索結果",
  readWrite: "編集可能",
  readOnly: "閲覧可能",
};
