import { MutationTree } from "vuex";
import { HeaderState } from "./state";

const mutations: MutationTree<HeaderState> = {
  setHambugerState(state, isHambugerMenu) {
    state.isHambugerMenu = isHambugerMenu;
  },

  setAccount(state, account: any) {
    if (account.fullName) {
      state.account.fullName = account.fullName;
    }
    if (account.avatar) {
      state.account.avatar = account.avatar;
    }
    if (account.id) {
      state.account.id = account.id;
    }
    if (account.role) {
      state.account.role = account.role;
    }
    if (account.planType != state.account.planType) {
      state.account.planType = account.planType;
    }
    if (account.primaryPaymentMethodInfo != state.account.primaryPaymentMethodInfo) {
      state.account.primaryPaymentMethodInfo =account.primaryPaymentMethodInfo;
    }
    if (account.email) state.account.email = account.email;
  },
};

export default mutations;
