export default {
  confirm: "確認",
  labelTitle: "契約書の確認",
  template: "テンプレート: ",

  saveAsTemplate: "テンプレートとして保存",
  allowTransfer: "契約書の転送を許可する",
  viewerListTitle: "閲覧者一覧",
  addViewer: "閲覧者の追加",
  emptyViewer: "「閲覧者の追加」を選択して、新しい閲覧者を追加します",

  viewerDetailSectionTop: "閲覧者の設定を確認してください",
  viewerEmailLabel: "<span style='color: #D64D10'>【必須】</span>閲覧者",
  viewerNameLabel: "<span style='color: #D64D10'>【必須】</span>閲覧者氏名",
  editViewerTitle: "閲覧者の設定を変更する",

  sendMessageTitle: "送信メッセージ(任意)",
  sendMessageHint: "契約書送信時のメッセージを追加できます",
  inputMessage: "メッセージを入力します",

  warningNotification: "アラート通知(任意)",
  warningNotificaionMessage:
    "日付と時刻を設定できるカスタマイズ可能なアラート通知を追加します",

  subject: "件名",
  addSubject: "件名を追加",

  message: "通知メッセージ",
  addMessage: "メッセージを入力します",

  receiver: "アラート通知受信者",
  addReceiver: "メールアドレスを入力してください",

  datePicker: "アラート通知送信日を選択",
  datePickerPlaceholder: "日付を選択してください",

  hoursPicker: "アラート通知送信時刻を選択",
  hoursPickerPlaceholder: "時刻を選択してください",

  advancedOption: "契約書の詳細情報",

  contractSummary: "契約の概要",
  contractExpiredTime: "契約締結日",
  contractStart: "契約開始日",
  contractEnd: "契約終了日",
  dateFormat: "yyyy年MM月dd日",

  cancelExpiredTime: "解約通知期限",
  autoUpdate: "自動更新の有無",

  controlNumber: "管理番号",
  transactionAmount: "取引金額",
  freeText: "フリーテキスト",
  numberOfFreeText: "（2000字程度）",

  customArea: "契約書の自由項目",
  customMessage: "任意の項目名を指定して追加できます。",

  labelHint: "タイトルを追加してください",
  valueHint: "値を入力してください",
  addCustomField: "契約書の自由項目の追加",

  confirmPopupTitle: "契約書を送信する",
  confirmPopupMessage:
    "契約書を送信する準備ができました。<br/>1人目の署名者に送信してもよろしいですか？",

  additionalContractDetail: "契約書の詳細情報",
  contractSummaryOptional: "(任意）契約の概要",

  contractExpiredTimeOptional: "(任意）契約締結日",
  contractStartOptional: "(任意）契約開始日",
  contractEndOptional: "(任意）契約終了日",
  cancelExpiredTimeOptional: "(任意）解約通知期限",

  autoUpdateOptional: "(任意）自動更新の有無",
  controlNumerOptional: "(任意）管理番号",
  transactionAmountOptional: "(任意）取引金額",

  productCategoryOptional: "(任意）商材カテゴリー（電気・ガス等）",
  productNameOptional: "(任意) 商材名",

  employeeNumberOptional: "(任意) 社員番号",
  chargePersonNameOptional: "(任意) 営業担当者氏名",

  contractPartnerOptonal: "(任意）契約相手の名称",
  freeTextOptional: "(任意）フリーテキスト（2000字程度)",

  contractFreeItem: "契約書の自由項目",
  contractFreeItemNote: "任意の項目名を指定して追加できます。",

  freeItemAddTitle: "タイトルを追加してください",
  freeItemAddValue: "値を入力してください",

  addFreeItem: "契約書の自由項目の追加",

  partnerName: "契約相手の名称",

  createAsTemplate: "雛形として保存",
};
