export default {
  tagLine: "UPGRADE PLAN",
  title: "料金プランと機能比較",
  subtitle:
    "Digital Signではご利用用途に合わせて、フリープランとスタンダードプランの2つのプランをご用意しております。初期費用無料、契約期間制限なし、アカウント無制限のお得なプラン内容で、ご導入企業様の課題を解決するため、最適なプランを個別にご提案いたします。",
  currentPlan: "現在のプラン",
  upgradeNow: "プランを変更する",
  upgradePlanPopupTitle: "プランを変更しませんか？",
  upgradePlanPopupMessage:
    "契約書一括作成・送信機能はスタンダードプランユーザーのみなので、 スタンダードプランに更新しませんか？",
  upgradePlanPopupMessage1:
    "契約書一括作成・送信機能はスタンダードプランユーザーのみなので、 ",
  upgradePlanPopupMessage2: "スタンダードプランに更新しませんか？",
  upgradePlanPopupBtn: "スタンダードプランに申し込み",

  section5TableHeader1: "FEATURES",
  section5TableHeader2Tagline: "フリープラン",
  section5TableHeader2Title: "無料",
  section5TableHeader3Tagline: "スタンダードプラン",
  section5TableHeader3Title: "月額22,000円（税込）※",
  section5TableNote:
    "※上記料金は、月間の電子契約締結回数が100回までの料金となります。100回を超えたら、1回あたり220円（税込）のご利用料金が発生します",
  triangleNote:"※「△」はスタンダードプランから送信された契約書でのみ可能",

  planFeatures1: "契約書一括作成・送信",
  planFeatures2: "電子契約締結",
  planFeatures3: "マイナンバーカード",
  planFeatures4: "メール認証署名",
  planFeatures5: "電子署名",
  planFeatures6: "タイムスタンプ",
  planFeatures7: "雛形",
  planFeatures8: "アラート",
  planFeatures9: "契約書保管、管理",
  planFeatures10: "フォルダ管理",
  planFeatures11: "検索機能",
  planFeatures12: "連絡先リスト",
  planFeatures13: "グループ管理",
  planFeatures14: "メンバー権限",

  plandetail1:"契約書の作成から関係者各所への送信まで一貫してでき、郵送費がかかりません。",
  plandetail2:"電子ファイル上で契約を締結することで、印紙税の削減が可能です。",
  plandetail3:"マイナンバーカードと紐づけることで、より信頼性の高い本人確認が可能です。",
  plandetail4:"電子ファイル上での契約署名が可能です。契約締結の大幅な時間短縮を実現しています。",
  plandetail5:"電子署名で誰が編集したかを証明し、なりすましを防止することで本人性を担保します。",
  plandetail6:"いつ・誰が編集したか、タイムスタンプによる確認が可能です。改ざん防止に繋がります。",
  plandetail7:"よく使用する契約書のテンプレートを登録することで、契約書作成時間の短縮が可能です。",
  plandetail8:"契約書ごとに、契約更新日をお知らせできます。契約更新漏れを防ぐことが可能です。",
  plandetail9:"データで管理することで書類の紛失等を防ぎます。",
  plandetail10:"フォルダ管理することで、整頓された管理が可能です。契約書を探す時間を短縮できます。",
  plandetail11:"契約書名や契約終了日などや取引金額など、絞り込みを適用した検索が可能です。",
  plandetail12:"顧客様の情報を管理しやすくできます。",
  plandetail13:"任意にグループを作成することで契約書管理が容易になります",
  plandetail14:"各契約書に、閲覧・編集権限の付与が可能です。より適した管理方法を設定できます。",
};
