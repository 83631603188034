export default {
  bannerText: "契約書を作成する",
  saveButton: "下書きとして保存",
  deleteButton: "作業を中止して破棄",

  editPlaceholderTitle: "署名者の追加",
  signerEmailLabel: "<span style='color: #D64D10'>【必須】</span>署名者",
  signerNameLabel: "<span style='color: #D64D10'>【必須】</span>署名者氏名",
  companyNameLabel: "（任意）会社名",
  passwordLabel: "（任意）アクセスコード",
  signerEditButton: "変更する",

  companyHint: "会社",
  signerDetailTitle: "署名者の追加",
  signerDetailSectionTop: "署名者の設定を確認してください",
  signerDetailAddEmail: "メンバーを招待する",
  editSignerTitle: "署名者の設定を変更する",
  editPlaceholder: "メールアドレス、または既存のメンバーの氏名を入力する",
  editPlaceholderButton: "編集する",
  checkAddContact: "連絡先に追加",
};
