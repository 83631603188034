import { Module } from "vuex";
import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";
import { HeaderState } from "./state";
import { RootState } from "../rootState";

export const state = new HeaderState();

const namespaced = true;

export const header: Module<HeaderState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
