export default {
  signContractButton: "契約書に署名する",
  transferSignerButton: "この契約書を転送する",
  denySignButton: "署名を拒否する",

  returnContract: "契約に戻る",
  loginByXID: "xIDアプリで署名する",

  transferPopupTitle: "契約書の転送",

  tagline: "TRANSFER COMPLETED",
  title: "転送しました",
  returnButton: "契約内容に戻る",

  xidPinSignTitle: "xIDアプリで署名する",

  signerListTitle: "署名者一覧",

  signingContract: "に署名する",

  pinLoading: "xIDアプリで署名中…",
  pinSuccess: "xIDアプリでの署名が完了しました。",
  pinError: "署名中にエラーが発生しました。<br/>もう一度署名してください。",

  pinErrorButton: "リトライ",

  requireLoginXIDTitle: "必要なログインXID",

  requireLoginXIDMessage:
    "この契約書に署名しますか？<br/>一度署名すると取り消すことはできません。",

  messageXID: "xIDの使用方法の詳細は<a href=https://x-id.me/ target=_blank><p style='color: #D64D10; font-weight:700;'>こちら</p></a>をご覧ください",

  transferMessage1: "署名者は<p style='color: #17B182;font-weight: 700;'>{lastName} {firstName}</p>に変更されました。",
  transferMessage2: "現在、契約書の閲覧のみ可能です。",

  messageXIDPin:
    "xIDアプリへ6桁の認証コードが送信されました。<br>画面に表示されている6桁の認証コードと同じものを、xIDアプリで選択して署名手続きを完了してください。",
};
