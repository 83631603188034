import { createApp, h } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import gtm from "./plugins/gtm";
import "./validation";

createApp({
  render: () => h(App),
})
  .use(i18n)
  .use(router)
  .use(store)
  .use(gtm)
  .mount("#app");
