export default {
  paymentTaglineText: "PAYMENT",
  paymentTitleText: "支払い",
  cardNumber: "カード番号",
  name: "姓 名",
  cardName: "カードに記載された名前",
  expiryDate: "有効期限",
  securityCode: "セキュリティーコード",
  saveCreaditCardInfor: "クレジットカード情報を保存する",
  monthlyWithdrawal: "毎月の引き出し",
  totalTaxIncluded: "商品合計 (税込み)",
  amount: "¥22,000",
  completepayment: "アップグレードを完了する ",
  cancelPayment: "支払いをキャンセルする",
  securutyCodeInfor:
    "CVV / CVCコード（カード検証値/コード）は、クレジットカード/デビットカードの裏面の白い署名ストリップの右側にあります。 VISAおよびMasterCardの場合、常に最後の3桁です。",

  confirmPayment: "更新する ",
  showDashboard: "ダッシュボードを表示",
  paymentCompleteTaglineText: "UPGRADED COMPLETED",
  paymentCompleteTitleText: "アップグレードが完了しました",
  acceptCreditCardTitle0: "スタンダードプランにアップグレードします。",
  acceptCreditCardTitle: "※アップグレードにはクレジットカード決済が必要です。",
  cardNumberPlaceholder: "0123 4567 8901 2345",
  cardNamePlaceholder: "姓 名",
  yearPlaceholder: "YY (年)",
  monthPlaceholder: " MM (月)",
  cvvPlaceholder: "CVV",
  paymentCompleteMessage:
    "スタンダードプランへのアップグレードが完了しました。",
  paymentCompleteMessage1: "すべての機能をご利用いただけます。",

  errorFailPayment: "チェックアウトプロセス中にエラーが発生しました",
  errorCancelPayment:
    "エラーが発生しました。しばらくしてからもう一度お試しください",
  upgradePlanPermissionTitle: "通知",
  upgradePlanPermissionMessage:
    "プランをアップグレードすることはできません。プランをアップグレードするには、オーナーに連絡してください。",
  upgradePlanPermissionBtn: "戻る",
};
