import { Module } from "vuex";
import mutations from "./mutations";
import { NotificationState } from "./state";
import getters from "./getters";
import { RootState } from "../rootState";

export const state = new NotificationState();

const namespaced = true;

export const notification: Module<NotificationState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
};
