import { GetterTree } from "vuex";
import { RootState } from "../rootState";
import { AccountState, HeaderState } from "./state";

const getters: GetterTree<HeaderState, RootState> = {
  getIsHambugerMenu(state: HeaderState): boolean {
    return state.isHambugerMenu;
  },

  getAccountInfo(state: HeaderState): AccountState {
    return state.account;
  },
};

export default getters;
