export default {
  back: "前に戻る",
  next: "次へ",
  pickTemplate: "テンプレートから選択",
  select: "選択する",
  upload: "新しく書類(PDF)をアップロード",
  uploadFile: "ドラッグアンドドロップしてファイルをアップロード",
  uploadedFiles: "アップロードされたファイル",
  addTo: "追加する",
  download: "ダウンロード",
  firstnameHint: "名",
  lastnameHint: "姓",
  email: "メールアドレス",
  changeTo: "変更する",
  editTo: "編集する",
  createTo: "作成する",
  sendTo: "送信する",
  optionalField: "（任意）",
  passwordHintCharacter: "1文字以上の英字",
  passwordHintNumber: "1文字以上の数字",
  passwordHintMin: "8文字以上",
  requiredText: "【必須】",
  selectPlaceHolder: "選択してください",
  dot: "。",
  loadMoreText: "もっと見る",
  square: "正方形",
  circle: "円形",
  saveChanges: "変更内容を保存",
};
