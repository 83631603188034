/// Detect whether [source] contains number characters
export const hasNumber = (source: string) => {
  return /\d/.test(source);
};

/// Detect whether [source] contains alphabet characters
export const hasAlphabet = (source: string) => {
  return /^.*[a-zA-Z]+.*$/.test(source);
};

export const isEmail = (value: string) => {
  if (
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
      value
    ) ||
    value.includes("--")
  ) {
    return false;
  }
  return true;
};

export const getFilePathFromUrl = (urlFileInCloudStorage: string) =>
  process.env.VUE_APP_API_CLOUD_URL + urlFileInCloudStorage;

export const getRelativePath = (url: string) =>
  url.replace(process.env.VUE_APP_API_CLOUD_URL as string, "");

export const buildContractEditBreadScrumPath = (
  folderId: string,
  folderName: string
) => {
  let folderPath = `/folder`;
  if (folderId && folderName)
    folderPath = folderPath + `?folderId=${folderId}&folderTitle=${folderName}`;
  return folderPath;
};

export const formatThounsandNumber = (thoundsandNumber: number) => {
  return thoundsandNumber ? thoundsandNumber.toLocaleString() : "";
};

export const truncate = (
  text: string,
  length = 20,
  appendText = "..."
): string => {
  if (text.length > length) {
    return text.trim().substring(0, length) + appendText;
  }
  return text;
};
