import { ActionTree } from "vuex";
import { RootState } from "../rootState";
import { HeaderState } from "./state";

const actions: ActionTree<HeaderState, RootState> = {
  setHambugerState({ commit }, state: boolean) {
    commit("setHambugerState", state);
  },

  setAccount({ commit }, account: any) {
    commit("setAccount", account);
  },
};

export default actions;
