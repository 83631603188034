export default {
  bannerText: "雛形を作成する",
  cancelEdit: "編集をキャンセル",

  labelTitle: "署名者の設定",
  signerSection: "この書類の署名者",
  addSigner: "署名者の追加",

  emptySignerMessage: "「署名者の追加」を選択して、新しい署名者を追加します。",

  protectSection: "セキュリティ",

  requireXIDSign: "xIDの署名を要求",
  requireTwoFactor: "2段階認証を要求",
  allowTransfer: "契約書の転送を許可する",
  needTimeStamp: "認定タイムスタンプを付与する",

  on: "オン",
  off: "オフ",

  backButton: "戻る",
  nextButton: "次へ",

  state1: "ファイルの追加",
  state2: "署名者の設定",
  state3: "契約内容の編集",
  state4: "契約書の確認",
  state5: "完了",

  addPopupTitle: "署名者の追加",
  addPopupMessage:
    "<span style='text-align:center;'>任意の宛先を設定してください。<br>宛先を設定せずに、契約書作成時に割り当てる場合には、<br>任意の管理名称を設定してください。</span>",
  addPopupLabel: "署名者",
  addPopupHint: "メールアドレス、または既存のメンバーの氏名を入力する",

  addMoreSigner: "「署名者の追加」を選択して、新しい署名者を追加します",
  emptyUser: "この名前のユーザーはいません",
  emptySelectedUser: "署名者を選択してください。",
  edit: "編集",
  delete: "削除",
};
