export default {
  // General
  createSuccess: "正常に作成されました",
  updateSuccess: "正常に更新されました",
  deleteSuccess: "正常に削除されました",
  moveSuccess: "正常に移動しました",
  // Folder-Template Page
  contractEditSuccess: "契約が正常に編集されました",
  contractMoveSuccess: "契約は正常に移動されました",
  contractRejectSuccess: "契約は正常に拒否されました",
  contractDeleteSuccess: "契約は正常に削除されました",
  contractRenameSuccess: "契約の名前が正常に変更されました",

  folderAddSuccess: "フォルダは正常に作成されました",
  folderEditSuccess: "フォルダは正常に編集されました",
  folderMoveSuccess: "フォルダは正常に移動されました",
  folderDeleteSuccess: "フォルダは正常に削除されました",
  folderRenameSuccess: "フォルダの名前が正常に変更されました",

  selectedDeleteSuccess: "選択したアイテムは正常に削除されました",
  fileNameExistError: "ファイル名はすでに存在します",
  fileUploadSuccess: "ドキュメントが正常にアップロードされました",

  // Contract
  draftCreateSuccess: "ドラフトとして正常に作成されました",
  // Sign Detail
  forwardContract: "契約書を転送しました",
  // Account Detail
  resendEmailSuccess: "確認メールが再送されました",
  // Contract Sumary
  templateCreateSuccess: "テンプレートとして正常に作成されました",
  // Folder Don't have permission to access
  accessPermissionError: "このフォルダにアクセスする権限がありません",

  createAlertNotifications: "正常に作成されました",
  updateAlertNotifications: "正常に更新されました",
  deleteAlertNotifications: "正常に削除されました",
};
