export default {
  section1: "基本情報",
  section2: "署名者の設定",
  section3: "セキュリティ設定",
  section4: "メール通知設定",
  section5: "所属しているグループ",

  avatar: "プロフィール画像",
  username: "氏名",
  role: "役職",
  phone: "電話番号",
  mail: "メールアドレス",
  permission: "アカウントの種類",

  editButton: "編集",
  addUserButton: "署名者の追加",
  emptyApprover:
    "ここで署名者を追加することで、あなたの契約書の署名者に自動的に追加されるようになります。",

  password: "パスワード",
  allowEmailNotifications: "メール通知を許可する",
  useXID: "xIDと連携する",
  on: "オン",
  off: "オフ",
  XIDDescribe:
    "xIDと連携する事でよりセキュアな契約オプションを使用することができるようになります。<br/>xIDアプリのインストールはこちらから。",
  XIDButton: "署名する",

  changeEmailMobileLabel: "保留中の新しいメール",

  addAuthPopupTitle: "署名者を追加",
  addAuthSearchHint: "メールアドレス、または既存のメンバーの名前を入力する",
  addAuthLabel: "", //:FIXMEバグ #2688文言一時削除
  addAuthButton: "追加する",

  deleteAuthButton: "署名者を削除する",

  authTwoFactor: "2要素認証を使用する",

  changePassword: "パスワードの変更",

  compulsory: "【必須】",
  oldPassword: "現在のパスワード",

  newPassword: "新しいパスワード（英数値含む8桁以上）",

  retypePassword: "新しいパスワード（英数値含む8桁以上）の再入力",

  confirmButton: "変更する",

  editName: "氏名の変更",
  editPosition: "役職の変更",
  editPhoneNumber: "電話番号の変更",
  surName: "姓",
  firstname: "公的",
  name: "名",
  lastname: "太郎",

  editEmail: "メールアドレスの変更",
  editEmailMessage:
    "に確認メールが送信されました。<br />受信トレイを確認し、メールに記載の手順に従って<br />変更を完了してください。",
  completeButton: "完了する",
  newEmail: "確認中の新しいメール",

  resendEmail: "確認メールを再送する",
  cancelChange: "変更を取り消す",
  resendEmailSuccess: "確認メールが再送されました",

  teamMembers: "メンバー一覧",
  memberProfile: "メンバープロフィール",
};
