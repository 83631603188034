import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { RootState } from "./rootState";
import { template } from "./template";
import { notification } from "./notification";
import { header } from "./header";
import { popup } from "./popup";
import { other } from "./other";
const store: StoreOptions<RootState> = {
  state: {
    version: "1.0.0", // a simple property
  },
  modules: {
    header,
    template,
    notification,
    popup,
    other,
  },
};

export default new Vuex.Store<RootState>(store);
