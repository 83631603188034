export default {
  bannerText: "おかえりなさい、",
  bannerText1: "おはようございます、",
  bannerText2: "こんにちは、",
  bannerText3: "こんばんは、",
  bannerTextEnd: " 様!",
  premiumTitle: "Digital sign スタンダードプラン",
  premiumDetail:
    "スタンダ―ドプランでは月額22,000円（税込）で、全ての機能要件をご利用頂けます。</br> ※上記料金は、月間の電子契約締結回数が100回までの料金となります。<br/>  100回を超えたら、1回あたり220円（税込）のご利用料金が発生します。",
  premiumButton: "詳細を見る",
  noCreditCard: "クレジットカードなし",

  chartTitle: "契約締結",
  topTileTitle: "署名が必要な契約書",

  needSignContract: "署名が必要な契約書",
  completeContract: "完了した契約",
  otherNotification: "その他の通知",

  blackSideTitle: "次の支払い",

  contractCompleted: "件の完了した契約",
  contractCreated: "件の契約を作成",
  paymentDeadline: "期限は",
  lastUpdated: "最終更新日",
  noContract: "現在、契約はありません。",

  countText: "件",
  barChartTitle: "締結時間",
  elapsedTime: "平均 {time}時間",
  rankingContractTitle: "契約書作成",
  rankingSignTitle: "署名",
  rankingTemplateTitle: "雛形",
  labelContract: "契約書",
  labelSigner: "署名者",
  emptyChart: "電子契約の締結を行うと、締結時間のチャートが表示されます。",
};
