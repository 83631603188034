export default {
  formatEmail: "メールの形式が不正になっています。",
  formatName: "このフィールドに数字を含めることはできません。",
  required: "入力が必要です。",
  passwordRepeatError: "パスワードが一致しません。",
  passwordDosentMacth: "新パスワードと再入力パスワードが一致しません。",
  invalidPassword: "無効なパスワード",
  wrongPassword: "パスワードが間違っています。",
  formatNumber: "数字でなければなりません",
  invalidPostalCode: "郵便番号が無効です",

  existEmail: "メールが存在します",

  formatControlNumber: "半角英数字記号でなければなりません",

  //CompanyName
  existName: "会社名はすでに使われています",

  //Upload Files
  errorOversizeFile: "32MB以上のファイルをアップロードすることはできません",
  errorOversizeAvatar: "200KB以上のファイルをアップロードすることはできません",
  errorMaxFiles: "最大{maxFiles}つのファイルしか選択できません",
  errorFileExtension: "{fileExtension}形式のドキュメントをアップロードできません",

  //load more list
  errorNoNewRecord: "新しい記録はありません",

  //sign-login
  wrongGuestCode: "コードが間違っています。もう一度お試しください",

  // Error Page
  errorConfirmTagline: "ERROR",
  errorConfirmButton: "ダッシュボードに戻る",
  errorHomeButtonName: "ホームページに戻る",
  errorDashboardButtonName: "ダッシュボードに戻る",
  errorBackToSignAgain: "再び署名するために戻ることはできません",
  errorBackToCreateAgain: "再度作成するために戻ることはできません",

  // Email
  errorEmailConfirmTitle: "パスワードをお忘れですか",
  errorEmailConfirmText:
    "URLが無効であるか、有効期限が切れています。しばらくしてからもう一度お試しください",

  // Credit Card Format
  errorCreditCardFormat: "クレジットカード番号の形式が正しくありません",
  errorFormatHolderName: "このフィールドはアルファベット文字のみを受け入れます",
  errorCardExpired: "このカードの有効期限が切れています。",
  errorCVV: "このフィールドは3桁または4桁のみを受け入れます",
};
