import { MutationTree } from "vuex";
import { ErrorPageState } from "./state";

const mutations: MutationTree<ErrorPageState> = {
  setErrorPage(state, errors: any) {
    state.errors.title = errors.title;
    state.errors.content = errors.content;
    state.errors.button = errors.button;
    state.errors.urlBtn = errors.urlBtn;
  },
};

export default mutations;
