import { MutationTree } from "vuex";
import { NotificationState } from "./state";
const mutations: MutationTree<NotificationState> = {
  showSuccessNotification(state, text: string) {
    state.enabledAnimation = false;
    state.notifications = [{
      id: new Date().getTime().toString(),
      text,
      type: "success",
    }];
    state.showInPopup = false;
  },
  showErrorNotification(state, text: string) {
    state.notifications = [{
      id: new Date().getTime().toString(),
      text,
      type: "error",
    }];
    state.enabledAnimation = false;
    state.showInPopup = false;
  },
  showPopupSuccessNotification(state, text: string) {
    state.enabledAnimation = false;
    state.notifications = [{
      id: new Date().getTime().toString(),
      text,
      type: "success",
    }];
    state.showInPopup = true;
  },
  showPopupErrorNotification(state, text: string) {
    state.enabledAnimation = false;
    state.notifications = [{
      id: new Date().getTime().toString(),
      text,
      type: "error",
    }];
    state.showInPopup = true;
  },
  removeNotification(state, id: string) {
    state.notifications = state.notifications.filter(
      (notification) => notification.id !== id
    );
  },
  removeAllNotification(state) {
    state.notifications = [];
  },
  enabledAnimation(state, value) {
    state.enabledAnimation = value;
  },
};

export default mutations;
