export default {
  loginTaglineText: "CONTRACT RECEIVED",
  loginTitleText: "契約書が届いています",
  loginEmail: "{email}",
  loginFirstInfoLine: "様に新しい契約書が届きました。",
  loginSecondInfoLine: "Digital Signのアカウントをお持ちの方は",
  loginThirdInfoLine: "ログインしてご利用ください。",
  loginButton: "ログイン",
  loginGuest: "アカウント登録しないで署名する",
  loginGuestContinue: "こちら",
  loginHowToUse: "Digital Signの使い方",

  accessTaglineText: "ACCESS CODE",
  accessTitleText: "アクセスコード",
  accessFirstInfoLine: "契約書にアクセスするためにはアクセスコードが必要です。",
  accessSecondInfoLine:
    "アクセスコードがわからない場合は送信者に確認してください。",
  accessPlaceholder: "アクセスコード",
  verifyButton: "確認",

  termsTaglineText: "TERMS AND PRIVACY POLICY",
  termsOfUse: "利用規約",
  privacyPolicy: "プライバシーポリシー",
  termsTitleText: "利用規約とプライバシーポリシー",
  termsCheckbox: "利用規約とプライバシーポリシーに同意します。",

  download: "ダウンロード",
  transferContract: "この契約書を転送する",
  viewHistory: "履歴を表示",
  viewViewer: "閲覧者を見る",
  signer: "署名者",
  signed: "署名",
  rejected: "却下",
  returnToContract: "契約に戻る",
  signContract: "契約書に署名する",
  transferSigner: "契約書の転送",
  transferSignature: "契約書の転送",
  rejectSignature: "署名せずに却下",
  signingContract: "に署名する",
  contractHistoryTitle: "の履歴",
  xidMessage: "xIDアプリの詳細は<a href=https://x-id.me/ target=_blank><p style='color: #D64D10; font-weight:700;'>こちら</p></a>をご覧ください",

  popupMessage1: "この契約書に署名しますか？",
  popupMessage2: "一度署名すると取り消すことはできません。",
  sign: "署名する",

  popupMessage3:
    "宛に署名確認コードが送信されました。 <br/>契約書に署名する場合は、メールに記載の署名確認コードを、<br/>以下フォームに入力して署名を完了してください。",
  popupPlaceholder: "署名確認コード",

  optionalCompanyName: "（任意）企業名",
  companyName: "会社名",
  optionalAccessCode: "（任意）アクセスコード",

  batchDownload: "一括ダウンロード",
  fileDownload: "ファイルのダウンロード",
  signingCertificateDownload: "署名証明書のダウンロード",
  denote:"表示する",

  signerName: "署名者氏名",

  uploadStampTitle: "印影を使用する",
  signOrginalStampTitle: "オリジナル印影を使用する",

  rejectPopupTitle: "署名せずに却下",
  rejectPopupMessage:
    "この契約を拒否してもよろしいですか？<br />一度拒否すると、再度署名することはできません。",
  reject: "却下する",

  editContract: "契約書を変更する",

  useOfficalImprint: " 印影を使用する",
  useOriginalImprint: "オリジナル印影を使用する",
  requestCodeAgain: "署名確認コードを再送する",

  date: "日付",

  companyNameHint: "〇〇〇〇株式会社",
  stampMessageOfficial: "こちらの印影を使用します。<br/>会社名や氏名を入力することで擬似的な印影を押印することができます。",
  stampMessageOriginal: "こちらの印影を使用します。<br/>変更が必要な場合には、アカウント管理 > 企業の詳細から設定を行うか、<br />オーナーにご確認ください。",
  useStampText: "使用する",

  objectText: "テキスト",
  objectStamp: "スタンプ",
  objectCheck: "チェック",

  inSignatureText: "只今、電子署名を行っております。<br>このまましばらくお待ち下さい。",
};
