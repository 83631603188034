import accountDetail from "./account-detail";
import common from "./common";
import contact from "./contact";
import contract from "./contract";
import contractGuest from "./contract-guest";
import contractSigner from "./contract-signer";
import contractSummary from "./contract-summary";
import contractVars from "./contract-vars";
import dashboard from "./dashboard";
import errors from "./errors";
import group from "./group";
import groupAdd from "./group-add";
import groupFolder from "./group-folder";
import groupMember from "./group-member";
import groupTemplate from "./group-template";
import folder from "./folder";
import footer from "./footer";
import header from "./header";
import invoice from "./invoice";
import login from "./login";
import memberProfile from "./member-profile";
import onboarding from "./onboarding";
import organizationDetail from "./organization-detail";
import pricingPlan from "./pricing-plan";
import sidebar from "./sidebar";
import sign from "./sign";
import teamMember from "./team-member";
import template from "./template";
import templateSigner from "./template-signer";
import templateSummary from "./template-summary";
import templateVars from "./template-vars";
import payment from "./payment";
import notification from "./notification";
export default {
  accountDetail,
  common,
  contact,
  contract,
  contractGuest,
  contractSigner,
  contractSummary,
  contractVars,
  dashboard,
  errors,
  group,
  groupAdd,
  groupFolder,
  groupMember,
  groupTemplate,
  folder,
  footer,
  header,
  invoice,
  login,
  memberProfile,
  onboarding,
  organizationDetail,
  pricingPlan,
  sign,
  sidebar,
  teamMember,
  template,
  templateSigner,
  templateSummary,
  templateVars,
  payment,
  notification,
};
