import { Module } from "vuex";
import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";
import { ErrorPageState } from "./state";
import { RootState } from "../rootState";

export const state = new ErrorPageState();

const namespaced = true;

export const other: Module<ErrorPageState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
