export class ContractViewer {
  id?: string;
  order = 0;
  userId = "";
  profilePicture = "";
  firstName?: string = "";
  lastName?: string = "";
  email = "";
  companyName = "";
  accessCode = "";

  constructor(
    id: string,
    order: number,
    userId: string,
    profilePicture: string,
    firstName: string,
    lastName: string,
    email: string,
    companyName: string,
    accessCode: string
  ) {
    this.id = id;
    this.order = order;
    this.userId = userId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.profilePicture = profilePicture;
    this.email = email;
    this.companyName = companyName;
    this.accessCode = accessCode;
  }

  static fromJson({
    id = "",
    order = 0,
    userId = "",
    profilePicture = "",
    viewerFirstName: firstName = "",
    viewerLastName: lastName = "",
    viewerEmail: email = "",
    viewerCompanyName: companyName = "",
    viewerAccessCode: accessCode = "",
  }) {
    return new ContractViewer(
      id,
      order,
      userId,
      profilePicture,
      firstName,
      lastName,
      email,
      companyName,
      accessCode
    );
  }

  toJson() {
    return {
      order: this.order,
      userId: this.userId,
      profilePicture: this.profilePicture,
      viewerFirstName: this.firstName,
      viewerLastName: this.lastName,
      viewerEmail: this.email,
      viewerCompanyName: this.companyName,
      viewerAccessCode: this.accessCode,
    };
  }
}
