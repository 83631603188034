import { CompanyState } from "@/models/contracts/CompanyState";
import { ContractSigner } from "@/models/contracts/ContractSigner";
import { ContractViewer } from "@/models/contracts/ContractViewer";
import { CustomField } from "@/models/contracts/CustomField";
import { FileInfo } from "@/models/contracts/FileInfo";
import { TemplateInformation } from "@/models/contracts/Information";
import { NotificationInformation } from "@/models/contracts/NotificationInformation";

export class TemplateState {
  id: string | null = null;
  folderId = "";
  folderName?: string = "";
  title = "";
  contractPartnerName = "";
  contractInformation = new TemplateInformation();
  files: Array<FileInfo> = [];
  contractSigners: Array<ContractSigner> = [];
  contractViewers: Array<ContractViewer> = [];
  contractCustomFields: Array<CustomField> = [];
  isEditing = false;
  isTemplate = true;
  isXIDRequired = false;
  isOnlyPartnerXIDRequired = false;
  is2FARequired = true;
  isSignerTransferAllowed = true;
  needTimeStamp = true;
  stepError = -1;
  createdFromTemplate = "";
  countSigned = 0;


  status = 0;
  message = "";
  notification = new NotificationInformation();
  company = new CompanyState();
}
