export default {
  addTemplate: "雛形の共有設定",
  messageAdd: "このグループがアクセス可能な雛形を追加してください。",
  searchHint: "共有設定したいフォルダ・書類を選択してください",
  selectTemplateButton: "選択する",
  addTemplateButton: "設定する",
  currentTemplate: "共有している雛形一覧",
  searchResult: "の{total}件の検索結果",
  readWrite: "編集可能",
  readOnly: "閲覧可能",
};
