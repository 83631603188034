export default {
  bannerText: "契約書を作成する",
  bannerSave: "下書きとして保存",
  bannerDelete: "作業を中止して破棄",
  title: "入力項目の設定",
  componentAdd: "コンポーネントを追加する",
  stamp: "印影追加",
  textField: "フリーテキスト追加",
  checkBox: "チェックボックス追加",

  state1: "書類の追加",
  state2: "署名者の設定",
  state3: "入力項目の設定",
  state4: "契約書の確認",
  state5: "完了",
};
