import moment from "moment";
import { GetterTree } from "vuex";
import { RootState } from "../rootState";
import { TemplateState } from "./state";
import { NotificationInformation } from "@/models/contracts/NotificationInformation";
import { CompanyState } from "@/models/contracts/CompanyState";
import { ContractSigner } from "@/models/contracts/ContractSigner";
import { ContractViewer } from "@/models/contracts/ContractViewer";
import { TemplateInformation } from "@/models/contracts/Information";

const getters: GetterTree<TemplateState, RootState> = {
  getTemplateInfo(state: TemplateState): TemplateState {
    return state;
  },

  getContractJson(state: TemplateState): string {
    const contractFiles = state.files.map((e, index) => e.toJson(index + 1));

    const contractInformation = TemplateInformation.prototype.toJson.call(
      state.contractInformation
    );

    if (!contractInformation.contractId)
      contractInformation.contractId = state.id || "";

    const obj = {
      id: state.id,
      folderId: state.folderId,
      isTemplate: state.isTemplate,
      title: state.title,
      contractPartnerName: state.contractPartnerName,
      isXIDRequired: state.isXIDRequired,
      isOnlyPartnerXIDRequired: state.isOnlyPartnerXIDRequired,
      is2FARequired: state.is2FARequired,
      isSignerTransferAllowed: state.isSignerTransferAllowed,
      needTimeStamp: state.needTimeStamp,
      createdFromTemplate: state.createdFromTemplate,

      status: state.status,
      message: state.message,

      contractInformation: contractInformation,
      contractSigners: state.contractSigners.map((e) => {
        const toJson = ContractSigner.prototype.toJson;
        return toJson.call(e);
      }),
      contractViewers: state.contractViewers.map((e) => {
        const toJson = ContractViewer.prototype.toJson;
        return toJson.call(e);
      }),
      contractCustomFields: state.contractCustomFields.map((e) => {
        return {
          contractId: state.id || "",
          order: e.order,
          title: e.title,
          value: e.value,
        };
      }),
      contractFiles: contractFiles,
    } as any;

    const notification = NotificationInformation.fromJson(state.notification);
    if (!notification.isEmpty()) obj.notification = notification.toJson();

    return JSON.stringify(obj);
  },

  getCompanyInfo(state: TemplateState): CompanyState {
    return state.company;
  },
};

export default getters;
