export default {
  buttonAdd: "グループを作成する",
  searchHint: "グループを検索する",
  tableTitle: "グループ名",

  bannerText: "グループ",

  optionEditInfo: "グループ情報を編集",
  optionEditPermission: "メンバーの編集",
  optionEditFolder: "書類の共有設定",
  optionEditTemplate: "雛形の共有設定",
  optionDelete: "グループを削除",

  deletePopupTitle: "グループの削除",
  deletePopupMessage:
    "を削除してもよろしいですか？<br/>削除するとこのグループは元に戻すことができません。",
  buttonPopup: "削除する",

  editPopupTitle: "グループ情報の編集",
  editGroupName: "グループ名",
  editGroupDescription: "(任意)グループの説明",
  editGroupButton: "編集する",

  emptyList:
    "現在、このフォルダにはグループがありません。<br>ボタン 「グループを作成する」を選択して、新しいグループを追加してください。",

  editGroupFolderTitle: "書類の共有設定",
  editGroupTemplateTitle: "雛形の共有設定",

  groupDetails: "グループの詳細",
  groupDescription: "グループの説明",
  emptyGroupMember: "このグループに属するグループメンバーはありません",
  deleteMember: "メンバーを削除"

};
