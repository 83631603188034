
import { computed, defineComponent, provide, reactive, ref } from "vue";

export default defineComponent({
  name: "App",
  setup() {
    const state = reactive({
      isMobile: false,
      scrollbarWidth: 0,
    });
    const app = ref(null);
    provide(
      "isMobile",
      computed(() => state.isMobile)
    );
    provide(
      "scrollbarWidth",
      computed(() => state.scrollbarWidth)
    );

    const onResize = () => {
      state.isMobile = window.innerWidth < 960;
    };

    const getScrollbarWidth = () => {
      // Creating invisible container
      const outer = document.createElement("div");
      outer.style.visibility = "hidden";
      outer.style.overflowY = "scroll"; // forcing scrollbar to appear
      (outer.style as any).msOverflowStyle = "scrollbar"; // needed for WinJS apps
      (app.value as any).appendChild(outer);

      // Creating inner element and placing it in the container
      const inner = document.createElement("div");
      outer.appendChild(inner);

      // Calculating difference between container's full width and the child width
      state.scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

      // Removing temporary elements from the DOM
      outer.parentNode?.removeChild(outer);
    };

    return {
      app,
      onResize,
      getScrollbarWidth,
    };
  },
  mounted() {
    this.onResize();
    this.getScrollbarWidth();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
});
/**
 * @vuese
 * @group Global
 * Root vue file
 */
