export default {
  chooseTemplate: "選択する",

  addFile: "書類の追加",
  createContract: "契約書を作成",
  newContract: "新しい契約書",
  cancelEditing: "編集をキャンセル",
  doneMesssage: "契約書が送信されました。",
  waitingMesssage: "送信先に送られたことを伝えて署名を待ちましょう。",
  createCleanContract: "新規で契約書を作成する",
  createOtherContractAsCopy: "複製して契約書を作成する",
  returnFolderA: "フォルダAに戻る",
  updateButtonText: "契約を更新する",
  templateTitle: "契約書名",
  folderPath: "フォルダ",
  partnerName: "契約相手の名称",
  partnerNameHint: "送信先の会社名などを入力してください",
  uploadedFile: "アップロードされたファイル",
  advancedOption: "契約書の詳細情報",
  popupCheckTemplate: "使用する",
  popupAddTemplate: "雛形を作成する",

  contractTransferMessage:
    "あなたが署名者ではない場合、契約書を転送することができます。<br>契約書の履歴に転送履歴が記載されます。",
  contractTransferTableLabel: "メールアドレス",
  contractTransferSearchHint:
    "メールアドレス、または既存のメンバーの名前を入力する",
  contractTransferReason: "転送理由",
  contractTransferReasonHint: "転送する理由を記入してください",

  contractTransferConfirm: "契約書を",
  contractTransferConfirmMessage:
    "</strong></span>に転送しますか？<br/>転送するとあなたは署名することができなくなります。",

  contractTransferTagError1: "100文字以上入力できません",
  contractTransferTagError2: "転送するユーザーをリストから1人選択します",

  contractDoneTitle: "完了",

  docxTagline: "COMPLETED",
  docxDoneTitle: "雛形の作成が完了しました",
  docxDoneMessage: "雛形が正しく作成されました。",
  docxDoneButton: "雛形一覧に戻る",

  return: "に戻る",
  transfer: "転送する",
  browse: "選択する",

  cancelMessage:
    "契約書の変更を終了しますか？<br/>変更内容は保存されません。",
  browseFolder: "フォルダを参照",

  placeholderTag: "宛先を割り当ててください",

  progressConfirm: "契約書の確認",

  signAfterCreate: "作成した契約書に署名する"
};
