export default {
  title: "入力項目の設定",
  titleMobile: "入力項目の設定 (3/5)",
  addStamp: "印影追加",
  addText: "テキスト追加",
  addCheckBox: "テキスト追加",
  placeHolder: "テキスト入力",
  placeHolderOptional: "テキスト入力",
  toggleButtonText1: "デフォルトでチェックを付ける",
  toggleButtonText2: "任意項目にする",
  addTitle: "コンポーネントを追加する",
  fontSize: "文字サイズ",
};
