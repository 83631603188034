export default {
  bannerTitle: "連絡先",

  searchHint: "連絡先を検索する",
  addButton: "連絡先を追加",
  memberName: "メンバー名",
  memberGroup: "所属部署/グループ",
  memberCompany: "会社名",
  showAll: "すべて表示",

  edit: "編集",
  hide: "非表示にする",
  show: "表示にする",
  delete: "削除",

  deleteTitle: "連絡先の削除",
  hideTitle: "連絡先の非表示",
  unHideTitle: "連絡先の表示",
  editTitle: "基本情報の編集",
  addTitle: "連絡先を追加",

  name: "<span style='color: #D64D10'>【必須】</span>姓/名",
  mail: "<span style='color: #D64D10'>【必須】</span>メールアドレス",
  company: "（任意）企業名",
  group: "（任意）所属部署/グループ ",
  changeButton: "変更する",
  addConfirmButton: "追加する",

  hideConfirm: "非表示にする",
  unHideConfirm: "表示にする",

  emptyList:
    "現在、このフォルダには連絡先がありません。<br>ボタン「連絡先を追加」を選択して、新しい連絡先を追加してください。",

  deleteMessage:
    "<span style='color: #17B182;font-weight: bold'>{lastName} {firstName}</span>を削除してもよろしいですか？<br/>削除すると元に戻すことはできません。",
  hideMessage:
    "<span style='color: #17B182;font-weight: bold'>{lastName} {firstName}</span>を非表示にしますか？<br/>連絡先は削除されず、一覧に表示されなくなります。",
  unHideMessage:
    "<span style='color: #17B182;font-weight: bold'>{lastName} {firstName}</span>を表示にしますか？<br/>一覧に非表示されなくなります。",
};
