export default {
  sort: "並び替え",
  buttonAdd: "契約書を作成する",
  uploadContract: "書類をアップロードする",
  createContract: "雛形から契約書を作成する",
  createFolder: "フォルダを作成する",
  contract: "契約書一覧",
  sign: "署名する",

  renameFileLabel: "ファイル名",
  renameFile: "ファイル名を変更",
  viewDetail: "詳細を見る",
  edit: "編集",
  editInformation: "契約書の詳細情報",
  download: "ダウンロード",
  editAccess: "共有の設定を見る",
  moveFolder: "フォルダを移動する",
  alertNotification: "アラート通知",
  resendEmailToSigner: "署名者に向けてメールを再送する",
  transferSigner: "契約書を転送する",
  rejectContract: "契約を拒否する",
  delete: "削除",
  cancelSelection: "選択をキャンセル",
  selectAction: "選択アクション",

  renamePopupTitle: "フォルダ名を変更",
  addPopupTitle: "フォルダの作成",
  editMemberPopupTitle: "アクセス権限を編集",
  moveFolderPopupTitle: "フォルダを移動する",

  lastUpdate: "最終更新日",
  signatureRequired: "署名が必要",

  folderSearchHint: "メール、姓／名、グループ",
  editNotificationTitle: "アラート通知の編集",
  deleteNotificationTitle: "アラート通知の削除",
  deleteNotificationMessage: "この通知を削除してもよろしいですか？",

  addPopupTitleLabel: "フォルダ名",
  allowSpecificAccessOff: "特定のメンバーへのアクセスを制限する（ON／OFF）",
  allowSpecificAccessOn: "特定のメンバーへのアクセスを制限する（ON）",
  allFolder: "契約書の管理",
  changeBtn: "変更する",

  deleteFolderTitle: "フォルダを削除",
  deleteContractTitle: "契約を削除",
  deleteFileTitle: "ファイルを削除",
  deleteMultipleTitle: "複数削除",
  deleteFolderMessage1: "を削除してもよろしいですか？",
  deleteFolderMessage2: "すべてのファイルとサブフォルダーも削除されます。",
  deleteContractMessage1: "を削除してもよろしいですか？",
  deleteContractMessage2: "契約は完全に削除されます。",
  deleteFileMessage1: "を削除してもよろしいですか？",
  deleteFileMessage2: "ファイルは完全に削除されます。",
  deleteMultipleMessage1: "それらを消去してもよろしいですか？",
  deleteMultipleMessage2: "選択したアイテムは完全に削除されます。",
  deleteFolderErrorMessage1: "は削除できません。",
  deleteFolderErrorMessage2:
    "このフォルダーには、署名された契約が含まれています。",
  deleteMultipleFolderErrorMessage1:
    "一部のフォルダには署名された契約が含まれているため、削除できませんでした",
  deleteMultipleFolderErrorMessage2:
    "削除する前に、署名した契約を別の契約に移動してください。",
  deleteConfirmButton: "削除する",
  noItem:
    "現在、このフォルダにはファイルがありません。<br/>ボタン 「契約書を作成する」を選択して、新しい契約を追加してください。",

  editAlertNotification: "アラート通知の編集",

  fileDetailTitle: "ファイルプレビュー",

  readWrite: "編集可能",
  readOnly: "閲覧可能",

  filterAll: "すべて",
  filter0: "下書き",
  filter1: "要確認",
  filter2: "先方確認中",
  filter3: "締結済み",
  filter4: "却下",

  sortNameAsc: "契約書名昇順",
  sortNameDesc: "契約書名降順",
  sortUpdatedAsc: "最終更新日昇順",
  sortUpdatedDesc: "最終更新日降順",

  moveFolderContract: "現在のフォルダ内の移動",

  editMemberPopupLabel: "メンバー・グループ一覧",

  add: "追加",
};
