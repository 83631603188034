export class ContractSigner {
  order?: number = 0;
  userId = "";
  profilePicture?: string = "";
  name?: string = "";
  firstName?: string = "";
  lastName?: string = "";
  email?: string = "";
  companyName?: string = "";
  placeholder?: string = "";
  accessCode?: string = "";
  isContact?: boolean = false;

  constructor(
    order: number,
    userId: string,
    profilePicture = "",
    firstName: string,
    lastName: string,
    email: string,
    companyName = "",
    placeholder = "",
    accessCode = "",
    isContact = false
  ) {
    this.order = order;
    this.userId = userId;
    this.profilePicture = profilePicture;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.companyName = companyName;
    this.placeholder = placeholder;
    this.accessCode = accessCode;
    this.isContact = isContact;
  }

  static fromJson({
    order = 0,
    userId = "",
    profilePicture = "",
    signerFirstName: firstName = "",
    signerLastName: lastName = "",
    signerEmail: email = "",
    signerCompanyName: companyName = "",
    placeHolder: placeholder = "",
    signerAccessCode: accessCode = "",
  }) {
    return new ContractSigner(
      order,
      userId,
      profilePicture,
      firstName,
      lastName,
      email,
      companyName,
      placeholder,
      accessCode,
      true
    );
  }

  setOrder(order: number) {
    this.order = order;
    return this;
  }

  toJson() {
    return {
      order: this.order,
      userId: this.userId ?? "",
      profilePicture: this.profilePicture || "",
      placeHolder: this.placeholder ?? "",
      signerEmail: this.email ?? "",
      signerLastName: this.lastName,
      signerFirstName: this.firstName,
      signerCompanyName: this.companyName ?? "",
      signerAccessCode: this.accessCode ?? "",
    };
  }
}
