export default {
  section1: "プロフィール設定",
  section2: "署名者の設定",
  section3: "セキュリティ設定",
  section4: "所属しているグループ",
  buttonEdit: "編集",
  buttonAdd: "署名者の追加",
  section2Empty:
    "アラート通知先のメールアドレスをデフォルト設定できます。<br />また、他のメニューから契約書ごとに変更することもできます。",
  avatar: "プロフィール画像",
  fullname: "氏名",
  role: "役職",
  phone: "電話番号",
  mail: "メールアドレス",
  permission: "アカウントの種類",
  password: "パスワード",

  groupOption: "メンバーの編集",
  folderOption: "書類の共有設定",
  templateOption: "雛形の共有設定",

  teamMember: "チームメンバー",
  memberProfile: "メンバープロフィール",
};
