export default {
  headerTitle: "グループを作成する",
  title: "グループ情報",
  groupName: "グループ名",
  groupDescription: " (任意)グループの説明",
  groupMember: "(任意)メンバー",
  groupNameHint: "グループ名",
  groupMemberHint: "メールアドレス、または氏名",

  buttonCancel: "キャンセル",
  buttonSubmit: "作成する",
};
