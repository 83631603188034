import { key, storage } from "@/lib/storage";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { nextTick } from "vue";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/pages/dashboard/DashBoard.vue"),
    meta: { title: "Home" },
    beforeEnter: (_, from, next) => {
      const localStorage = storage.getLocalStorage();
      const userType = localStorage.get("userType");
      const sessionId = localStorage.get(key.sessionId);
      if (sessionId&&userType==="user") next({ path: "/dashboard" });
      else next({ path: "/login" });
    },
  },

  {
    path: "/xid-login",
    component: () => import("@/views/pages/login/XIDLogin.vue"),
    meta: { title: "XIDでログイン" },
  },

  {
    path: "/forgot-password",
    component: () => import("@/views/layouts/LoginLayout.vue"),
    children: [
      {
        // 2-1 Password
        path: "request-code",
        meta: { title: "パスワードをお忘れの方" },
        name: "ForgotPassword",
        component: () => import("@/views/pages/login/ForgotPassword.vue"),
      },
      {
        // 2-2 Password Confirming
        path: "email-confirm",
        meta: { title: "パスワードをお忘れの方" },
        name: "ForgotPasswordConfirm",
        component: () =>
          import("@/views/pages/login/ForgotPasswordConfirm.vue"),
      },
      {
        // 2-3 Password Email Confirmed
        path: "email-confirmed",
        meta: { title: "パスワード設定" },
        name: "EmailConfirmed",
        component: () => import("@/views/pages/login/PasswordEmailConfirm.vue"),
      },
      {
        // 2-4 Password New
        path: "password-new",
        meta: { title: "パスワード設定" },
        name: "PasswordNew",
        component: () => import("@/views/pages/login/PasswordNew.vue"),
      },
      {
        // 2-6 Password New Success
        path: "password-new-success",
        meta: { title: "パスワード設定" },
        name: "PasswordNewSuccess",
        component: () => import("@/views/pages/login/PasswordNewSuccess.vue"),
      },
    ],
  },

  // {
  //   path: "/test",
  //   name: "Test",
  //   component: () => import("@/views/Test.vue"),
  // },

  {
    path: "/login",
    component: () => import("@/views/layouts/LoginLayout.vue"),
    beforeEnter: (to, from, next) => {
      const localStorage = storage.getLocalStorage();
      const sessionId = localStorage.get(key.sessionId);
      const userType = localStorage.get("userType");
      if (sessionId&&userType==="user") next({ path: "/dashboard" });
      next();
    },
    children: [
      {
        path: "",
        meta: { title: "ログイン" },
        name: "Login",
        component: () => import("@/views/pages/login/Login.vue"),
        // props: true,
        props: (route) => ({
          ...route.params,
          ...route.query,
        }),
      },
      {
        path: "/signup",
        meta: { title: "アカウント新規作成" },
        name: "SignUp",
        component: () => import("@/views/pages/login/Signup.vue"),
        props: { isSentConfirmMail: false },
      },
      {
        path: "sent-confirm-email",
        meta: { title: "アカウント新規作成" },
        name: "SentConfirmEmail",
        component: () => import("@/views/pages/login/Signup.vue"),
        props: { isSentConfirmMail: true },
      },

      {
        path: "2fa",
        meta: { title: "2要素認証" },
        name: "TwoFactor",
        component: () => import("@/views/pages/login/2Fa.vue"),
        props: true,
      },
      {
        path: "2fa-require",
        meta: { title: "2要素認証" },
        name: "TwoFactorRequire",
        component: () => import("@/views/pages/login/2FaRequire.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/pricing-plan",
    name: "PricingPlan",
    meta: { title: "料金プランと機能比較" },
    component: () => import("@/views/pages/pricing-plan/PricingPlan.vue"),
  },
  /*<------------------------------------------------------- ONBOARDING ------------------------------------------------------->*/

  {
    path: "/onboarding",
    component: () => import("@/views/layouts/OnBoarding.vue"),
    children: [
      {
        path: "",
        meta: { title: "Digital Signへようこそ" },
        name: "Welcome",
        component: () => import("@/views/pages/onboarding/Welcome.vue"),
      },
      {
        path: "terms",
        meta: { title: "利用規約とプライバシーポリシー" },
        name: "Terms",
        component: () => import("@/views/pages/onboarding/Terms.vue"),
      },
      {
        path: "organization",
        meta: { title: "企業情報" },
        name: "Organization",
        component: () => import("@/views/pages/onboarding/Organization.vue"),
      },
      {
        path: "profile",
        meta: { title: "マイページ" },
        name: "Profile",
        component: () => import("@/views/pages/onboarding/Profile.vue"),
      },
      {
        path: "authentification",
        meta: { title: "2要素認証" },
        name: "Authentification",
        component: () =>
          import("@/views/pages/onboarding/Authentification.vue"),
      },
      {
        path: "complete",
        meta: { title: "アカウント情報の入力完了" },
        name: "Complete",
        component: () => import("@/views/pages/onboarding/Complete.vue"),
      },
    ],
  },

  /*<-------------------------------------------------------AFTER LOGIN VIEW------------------------------------------------------->*/
  {
    // redirect to this route if user had loggin
    // redirect by route name not path because this has the same path with index
    path: "/",
    name: "AfterLoginView",
    component: () => import("@/views/layouts/AfterLoginLayout.vue"),
    beforeEnter: (to, from, next) => {
      const localStorage = storage.getLocalStorage();
      const sessionId = localStorage.get(key.sessionId);
      if (!sessionId) {
        next({
          name: "Login",
          params: {
            afterLoginData: JSON.stringify(to),
          },
        });
      }
      next();
    },
    children: [
      //after login child page
      {
        path: "/dashboard",
        name: "Dashboard",
        meta: { title: "ホーム" },
        component: () => import("../views/pages/dashboard/DashBoard.vue"),
      },

      {
        path: "/contract",
        name: "Contract",
        meta: { title: "書類の追加" },

        component: () => import("../views/pages/contract/Contract.vue"),
        beforeEnter: (to, from, next) => {
          const account = store.getters["header/getAccountInfo"];
          if (account.planType == 1) next();
          next({ path: "/pricing-plan" });
        },
      },

      {
        path: "/contract/detail",
        name: "ContractDetail",
        meta: { title: "契約書の詳細" },
        component: () => import("../views/pages/contract/ContractDetail.vue"),
      },

      {
        path: "/contract/signers",
        name: "ContractSigners",
        meta: { title: "署名者の設定" },

        component: () => import("../views/pages/contract/ContractSigners.vue"),
      },

      {
        path: "/contract/summary",
        name: "ContractSummary",
        meta: { title: "契約書の確認" },

        component: () => import("../views/pages/contract/ContractSummary.vue"),
      },

      {
        path: "/contract/done",
        name: "ContractDone",
        meta: { title: "契約書の作成完了" },

        component: () => import("../views/pages/contract/ContractDone.vue"),
      },

      {
        path: "/contract/vars",
        name: "ContractVars",
        meta: { title: "入力項目の設定" },

        component: () => import("../views/pages/contract/ContractVars.vue"),
      },

      {
        path: "/folder",
        name: "Folder",
        meta: { title: "契約書の管理" },

        component: () => import("../views/pages/folder/Folder.vue"),
        props: true,
      },

      {
        path: "/files/:id",
        name: "FileDetail",
        meta: { title: "ファイルプレビュー" },

        component: () => import("../views/pages/folder/FileDetail.vue"),
        props: true,
      },

      {
        path: "/template/:id?",
        name: "Template",

        component: () => import("../views/pages/template/Template.vue"),
        children: [
          {
            path: "",
            name: "TemplateList",
            meta: { title: "雛形の管理" },

            component: () => import("../views/pages/template/TemplateList.vue"),
            props: true,
          },
          {
            path: "file-and-info",
            name: "TemplateFileAndInfo",
            meta: { title: "書類の追加" },

            component: () =>
              import("../views/pages/template/TemplateFileAndInfo.vue"),
          },
          {
            path: "signers",
            name: "TemplateSigner",
            meta: { title: "署名者の設定" },

            component: () =>
              import("../views/pages/template/TemplateSigner.vue"),
          },
          {
            path: "vars",
            name: "TemplateVars",
            meta: { title: "入力項目の設定" },

            component: () => import("../views/pages/template/TemplateVars.vue"),
          },
          {
            path: "summary",
            name: "TemplateSummary",
            meta: { title: "雛形の確認" },

            component: () =>
              import("../views/pages/template/TemplateSummary.vue"),
          },
          {
            path: "done",
            name: "TemplateDone",
            meta: { title: "雛形の作成完了" },

            component: () => import("../views/pages/template/TemplateDone.vue"),
            props: true,
          },
        ],
      },

      {
        path: "/contact",
        name: "Contact",
        meta: { title: "顧客一覧" },

        component: () => import("../views/pages/contact/Contact.vue"),
      },

      {
        path: "/organization-details",
        name: "OrganizationDetails",
        meta: { title: "企業の詳細" },

        component: () =>
          import("../views/pages/organization-detail/OrganizationDetail.vue"),
      },

      {
        path: "/team-member",
        name: "TeamMember",
        meta: { title: "メンバー一覧" },

        component: () => import("../views/pages/team-member/TeamMember.vue"),
        props: true,
      },

      {
        path: "/member-profile/:userID",
        name: "MemberProfile",
        meta: { title: "メンバープロフィール" },

        component: () => import("../views/pages/team-member/MemberProfile.vue"),
        props: (route) => ({
          isInvitingMember: route.query.isInvitingMember,
          ...route.query,
        }),
      },

      {
        path: "/group",
        name: "Group",
        meta: { title: "グループ" },

        component: () => import("../views/pages/group/Group.vue"),
      },

      { 
        path: "/group/details",
        name: "GroupDetails",
        meta: { title: "グループの詳細" },
        component: () => import("../views/pages/group/GroupDetails.vue"),
        props: (route) => ({
          groupName: route.query.groupName,
          groupID: route.query.groupID,
          ...route.query,
        }),
      },

      {
        path: "/group/group-add",
        name: "GroupAdd",
        meta: { title: "グループの作成" },

        component: () => import("../views/pages/group/GroupAdd.vue"),
      },

      {
        path: "/group/group-folder",
        name: "GroupFolder",
        meta: { title: "書類の共有設定" },
        component: () => import("../views/pages/group/GroupFolder.vue"),
        props: (route) => ({
          groupName: route.query.groupName,
          groupID: route.query.groupID,
          ...route.query,
        }),
      },

      {
        path: "/group/group-member",
        name: "GroupMember",
        meta: { title: "メンバーの編集" },

        component: () => import("../views/pages/group/GroupMember.vue"),
        props: (route) => ({
          groupName: route.query.groupName,
          groupID: route.query.groupID,
          ...route.query,
        }),
      },

      {
        path: "/group/group-template",
        name: "GroupTemplate",
        meta: { title: "雛形の共有設定" },

        component: () => import("../views/pages/group/GroupTemplate.vue"),
        props: (route) => ({
          groupName: route.query.groupName,
          groupID: route.query.groupID,
          ...route.query,
        }),
      },

      {
        path: "/invoice",
        name: "Invoice",
        meta: { title: "請求書" },

        component: () => import("../views/pages/invoice/Invoice.vue"),
      },
      {
        path: "/invoice/invoice-payment-updated",
        name: "InvoicePayment",
        meta: { title: "請求書" },

        component: () =>
          import("../views/pages/invoice/InvoicePaymentUpdated.vue"),
      },

      {
        path: "/account",
        name: "AccountDetail",
        meta: { title: "マイページ" },

        component: () => import("../views/pages/account/AccountDetail.vue"),
      },
    ],
  },
  /*<------------------------------------------------------- SIGN ------------------------------------------------------->*/
  {
    path: "/sign",
    component: () => import("@/views/layouts/ContractLoginLayout.vue"),
    children: [
      {
        path: "contract-login",
        meta: { title: "契約書の受信" },
        name: "ContractLogin",
        component: () => import("@/views/pages/sign/ContractLogin.vue"),
      },
      {
        path: "access-code",
        meta: { title: "アクセスコード" },
        name: "Access Code",
        component: () => import("@/views/pages/sign/AccessCode.vue"),
      },
      {
        path: "terms",
        meta: { title: "利用規約とプライバシーポリシー" },
        name: "ContractTerms",
        component: () => import("@/views/pages/sign/ContractTerms.vue"),
      },
    ],
  },
  {
    path: "/sign",
    component: () => import("@/views/layouts/AfterLoginLayout.vue"),
    children: [
      {
        path: "details",
        meta: { title: "契約書の確認" },
        name: "SignDetails",
        component: () => import("@/views/pages/sign/SignDetails.vue"),
        props: (route) => ({
          contractID: route.query.contractId,
          ...route.query,
        }),
      },
      {
        path: "done",
        meta: { title: "契約書の確認" },
        name: "SignDone",
        component: () => import("@/views/pages/sign/SignDone.vue"),
        props: (route) => ({
          contractID: route.query.contractId,
          isTransferSuccess: route.params.isTransferSuccess,
          ...route.query,
        }),
      },
    ],
  },
  /*<------------------------------------------------------- PAYMENT ------------------------------------------------------->*/
  {
    path: "/payment",
    component: () => import("@/views/layouts/Payment.vue"),
    props: { isLoggedIn: true },
    children: [
      {
        path: "",
        meta: { title: "決済情報" },
        name: "Payment",
        component: () => import("@/views/pages/payment/Payment.vue"),
        props: true,
      },
      {
        path: "payment-complete",
        meta: { title: "アップグレード完了" },
        name: "PaymentComplete",
        component: () => import("@/views/pages/payment/PaymentComplete.vue"),
      },
    ],
  },
  /*<------------------------------------------------------- GUEST CONTRACT------------------------------------------------------->*/
  {
    path: "/guest",
    component: () => import("@/views/layouts/GuestContractLayout.vue"),
    children: [
      {
        path: "contract-guest",
        name: "ContractGuest",
        meta: { title: "契約書の確認" },

        component: () =>
          import("../views/pages/contract-guest/ContractGuest.vue"),
        props: (route) => ({
          contractID: route.query.contractId,
          ...route.query,
        }),
      },
      {
        path: "contract-detail-readonly",
        name: "ContractDetailReadonly",
        meta: { title: "契約書の確認" },

        component: () =>
          import("../views/pages/contract-guest/ContractDetailReadonly.vue"),
        props: (route) => ({
          contractID: route.query.contractId,
          isUser: route.query.isUser === "true" ? true : false,
          readonly: true,
          ...route.query,
        }),
      },
      {
        path: "guest-sign-done",
        name: "GuestSignDone",
        meta: { title: "契約書の確認" },

        component: () => import("../views/pages/sign/SignDone.vue"),
        props: (route) => ({
          contractID: route.query.contractId,
          isGuest: true,
          ...route.query,
        }),
      },
    ],
  },
  {
    path: "/guest/transfer-done",
    name: "GuestTransferDone",
    meta: { title: "契約書の転送完了" },

    component: () =>
      import("../views/pages/contract-guest/GuestTransferDone.vue"),
    props: true,
  },
  {
    path: "/error",
    component: () => import("@/views/layouts/LoginLayout.vue"),
    meta: { title: "Error" },

    beforeEnter: (to, from, next) => {
      if (to.query.error) {
        next();
      } else {
        next({ path: "/" });
      }
    },
    children: [
      {
        path: "",
        meta: { title: "エラー" },
        name: "ErrorPage",
        component: () => import("@/views/pages/ErrorPage.vue"),
        props: true,
      },
    ],
  },
  { path: "/my-account/xid-linked", redirect: "/account" },
  { path: "/:pathMatch(.*)", redirect: "/login" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to, from) => {
  nextTick(() => {
    document.title = to.meta.title + " | Digital Sign" || ("Digital Sign" as any);
  });
});

export default router;
