export default {
  contact: "お問い合わせ",
  login: "ログイン",
  signUp: "新規会員登録",

  downloadMaterial: "資料ダウンロード",

  backHomeBtn: "ホームに戻る",

  search: "契約書の検索",
  resultNotBeFound: "結果は見つかりませんでした。",
  searchPlaceHolder:
    "契約のタイトル、フォルダのタイトル、またはファイル名で契約を検索します",
  upgradePlan: "プランを変更",
  searchFilter: "高度な検索",
  autoUpdateDefault: "情報を選択",
  conditionDefault: "条件を選択",
  buttonAdd: "検索条件を追加する",
  keywordSearch: "キーワードを検索する",
  selectDate: "日付を選択",
  cancel: "キャンセル",
  searchButton: "探す",
  true: "真",
  searchResultTitle: "の検索結果",
  searchResultTitle2: "条件に一致する",
  searchResultTitle21: "件の検索結果が見つかりました",
  clearSearchResult: "検索結果をクリア",
  delete: "削除",

  myAccount: "マイページ",
  logout: "ログアウト",
};
