import { GetterTree } from "vuex";
import { RootState } from "../rootState";
import { ErrorPageState, Errors } from "./state";

const getters: GetterTree<ErrorPageState, RootState> = {
  getErrorPage(state: ErrorPageState): Errors {
    return state.errors;
  },
};

export default getters;
