import { GetterTree } from "vuex";
import { RootState } from "../rootState";
import { NotificationState } from "./state";

const getters: GetterTree<NotificationState, RootState> = {
  getListNotifications(state: NotificationState): any[] {
    return state.notifications;
  },
  getEnabledAnimation(state: NotificationState): boolean {
    return state.enabledAnimation;
  },
};

export default getters;
